import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import FordermitgliedComponent from "../../components/foerdermitgliedComponent"
import CrosshairTile from "../../components/crosshairTile"
import Rocket from "../../components/rocket"

import * as sponsorenStyles from "../../styles/sponsoren.module.scss"

class Sponsoren extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const edges = data.allMarkdownRemark.edges
    const foerdermitgliedImage = data.foerdermitgliedImage.edges[0].node
    const node = data.seoInfo.edges[0].node

    const edgesSortedByFrontmatterTitle = edges.sort((e1, e2) => e1.node.frontmatter.title.localeCompare(e2.node.frontmatter.title))

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title}
             description={node.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingTop paddingMobile paddingMobileTop>
            <Rocket />
            <div className={`${sponsorenStyles.sponsorenHeading} pt-5`}><h1
              className="has-text-centered">Premium-Sponsoren und Aussteller</h1></div>
            <div className="columns is-mobile is-multiline">
              {edgesSortedByFrontmatterTitle.map((edge) => {
                if (edge.node.fileAbsolutePath.includes("/sponsoren/premium")) {
                  return (
                    <div className={`column is-2-desktop is-4-tablet is-6-mobile p-1`}
                         style={{ height: "147px", padding: "0" }} key={edge.node.fields.slug}>
                      <CrosshairTile>
                        <div style={{ width: "100%", height: "100%" }}>
                          <a href={`#${edge.node.frontmatter.title}`}>
                            <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }}
                                 fixed={edge.node.frontmatter.image.childImageSharp.fixed} alt="" />
                          </a>
                        </div>
                      </CrosshairTile>
                    </div>)
                }
              })}
            </div>
            <div className={`${sponsorenStyles.sponsorenHeading} pt-6`}><h1 className="has-text-centered">Premium-Light-Sponsoren</h1>
            </div>
            <div className="columns is-mobile is-multiline">
              {edgesSortedByFrontmatterTitle.map((edge) => {
                if (edge.node.fileAbsolutePath.includes("/sponsoren/light")) {
                  return (
                    <div className={`column is-2-desktop is-4-tablet is-6-mobile p-1`}
                         style={{ height: "147px", padding: "0" }} key={edge.node.fields.slug}>
                      <CrosshairTile>
                        <div style={{ width: "100%", height: "100%" }}>
                          <a href={`#${edge.node.frontmatter.title}`}>
                            <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }}
                                 fixed={edge.node.frontmatter.image.childImageSharp.fixed} alt="" />
                          </a>
                        </div>
                      </CrosshairTile>
                    </div>)
                }
              })}
            </div>
            <div className={`${sponsorenStyles.sponsorenHeading} pt-6`}><h1
              className="has-text-centered">Preissponsoren</h1></div>
            <div className="columns is-mobile is-multiline">
              {edgesSortedByFrontmatterTitle.map((edge) => {
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  return (
                    <div className={`column is-2-desktop is-4-tablet is-6-mobile p-1`}
                         style={{ height: "147px", padding: "0" }} key={edge.node.fields.slug}>
                      <CrosshairTile>
                        <div style={{ width: "100%", height: "100%" }}>
                          <a href={`#${edge.node.frontmatter.title}`}>
                            <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }}
                                 fixed={edge.node.frontmatter.image.childImageSharp.fixed} alt="" />
                          </a>
                        </div>
                      </CrosshairTile>
                    </div>)
                }
              })}
            </div>
            <div className={`${sponsorenStyles.sponsorenHeading} pt-6`}><h1
              className="has-text-centered">Unterstützer</h1></div>
            <div className="columns is-mobile is-multiline">
              {edgesSortedByFrontmatterTitle.map((edge) => {
                if (edge.node.fileAbsolutePath.includes("/sponsoren/unterstuetzer")) {
                  return (
                    <div className={`column is-2-desktop is-4-tablet is-6-mobile p-1`}
                         style={{ height: "147px", padding: "0" }} key={edge.node.fields.slug}>
                      <CrosshairTile>
                        <div style={{ width: "100%", height: "100%" }}>
                          <a href={`#${edge.node.frontmatter.title}`}>
                            <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }}
                                 fixed={edge.node.frontmatter.image.childImageSharp.fixed} alt="" />
                          </a>
                        </div>
                      </CrosshairTile>
                    </div>)
                }
              })}
            </div>
            <div>
              {edgesSortedByFrontmatterTitle.map((edge) => {
                const sponsor = edge.node
                let displayJobButton = true
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  displayJobButton = false
                }
                if (edge.node.fileAbsolutePath.includes("/sponsoren/premium")) {
                  return (
                    <div className="my-6" id={edge.node.frontmatter.title} key={edge.node.fields.slug}>
                      <FordermitgliedComponent image={sponsor.frontmatter.image} homepage={sponsor.frontmatter.homepage}
                                               title={sponsor.frontmatter.title}
                                               facebooklink={sponsor.frontmatter.facebooklink}
                                               email={sponsor.frontmatter.email}
                                               linkedinlink={sponsor.frontmatter.linkedinlink}
                                               city={sponsor.frontmatter.city}
                                               twitterlink={sponsor.frontmatter.twitterlink}
                                               street={sponsor.frontmatter.street}
                                               xinglink={sponsor.frontmatter.xinglink}
                                               instagramlink={sponsor.frontmatter.instagramlink}
                                               youtubelink={sponsor.frontmatter.youtubelink} html={sponsor.html}
                                               foerdermitgliedLogo={foerdermitgliedImage}
                                               isFoerdermitglied={sponsor.frontmatter.isFoerdermitglied}
                                               displayJobButton={displayJobButton}
                                               jobwalllink={sponsor.frontmatter.jobwalllink} />
                    </div>
                  )
                }
              })}
              {edgesSortedByFrontmatterTitle.map((edge) => {
                const sponsor = edge.node
                let displayJobButton = true
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  displayJobButton = false
                }
                if (edge.node.fileAbsolutePath.includes("/sponsoren/light")) {
                  return (
                    <div className="my-6" id={edge.node.frontmatter.title} key={edge.node.fields.slug}>
                      <FordermitgliedComponent image={sponsor.frontmatter.image} homepage={sponsor.frontmatter.homepage}
                                               title={sponsor.frontmatter.title}
                                               facebooklink={sponsor.frontmatter.facebooklink}
                                               email={sponsor.frontmatter.email}
                                               linkedinlink={sponsor.frontmatter.linkedinlink}
                                               city={sponsor.frontmatter.city}
                                               twitterlink={sponsor.frontmatter.twitterlink}
                                               street={sponsor.frontmatter.street}
                                               xinglink={sponsor.frontmatter.xinglink}
                                               instagramlink={sponsor.frontmatter.instagramlink}
                                               youtubelink={sponsor.frontmatter.youtubelink} html={sponsor.html}
                                               foerdermitgliedLogo={foerdermitgliedImage}
                                               isFoerdermitglied={sponsor.frontmatter.isFoerdermitglied}
                                               displayJobButton={displayJobButton}
                                               jobwalllink={sponsor.frontmatter.jobwalllink} />
                    </div>
                  )
                }
              })}
              {edgesSortedByFrontmatterTitle.map((edge) => {
                const sponsor = edge.node
                let displayJobButton = true
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  displayJobButton = false
                }
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  return (
                    <div className="my-6" id={edge.node.frontmatter.title} key={edge.node.fields.slug}>
                      <FordermitgliedComponent image={sponsor.frontmatter.image} homepage={sponsor.frontmatter.homepage}
                                               title={sponsor.frontmatter.title}
                                               facebooklink={sponsor.frontmatter.facebooklink}
                                               email={sponsor.frontmatter.email}
                                               linkedinlink={sponsor.frontmatter.linkedinlink}
                                               city={sponsor.frontmatter.city}
                                               twitterlink={sponsor.frontmatter.twitterlink}
                                               street={sponsor.frontmatter.street}
                                               xinglink={sponsor.frontmatter.xinglink}
                                               instagramlink={sponsor.frontmatter.instagramlink}
                                               youtubelink={sponsor.frontmatter.youtubelink} html={sponsor.html}
                                               foerdermitgliedLogo={foerdermitgliedImage}
                                               isFoerdermitglied={sponsor.frontmatter.isFoerdermitglied}
                                               displayJobButton={displayJobButton}
                                               jobwalllink={sponsor.frontmatter.jobwalllink} />
                    </div>
                  )
                }
              })}
              {edgesSortedByFrontmatterTitle.map((edge) => {
                const sponsor = edge.node
                let displayJobButton = false
                if (edge.node.fileAbsolutePath.includes("/sponsoren/preissponsoren")) {
                  displayJobButton = false
                }
                if (edge.node.fileAbsolutePath.includes("/sponsoren/unterstuetzer")) {
                  return (
                    <div className="my-6" id={edge.node.frontmatter.title} key={edge.node.fields.slug}>
                      <FordermitgliedComponent image={sponsor.frontmatter.image} homepage={sponsor.frontmatter.homepage}
                                               title={sponsor.frontmatter.title}
                                               facebooklink={sponsor.frontmatter.facebooklink}
                                               email={sponsor.frontmatter.email}
                                               linkedinlink={sponsor.frontmatter.linkedinlink}
                                               city={sponsor.frontmatter.city}
                                               twitterlink={sponsor.frontmatter.twitterlink}
                                               street={sponsor.frontmatter.street}
                                               xinglink={sponsor.frontmatter.xinglink}
                                               instagramlink={sponsor.frontmatter.instagramlink}
                                               youtubelink={sponsor.frontmatter.youtubelink} html={sponsor.html}
                                               foerdermitgliedLogo={foerdermitgliedImage}
                                               isFoerdermitglied={sponsor.frontmatter.isFoerdermitglied}
                                               displayJobButton={displayJobButton}
                                               jobwalllink={sponsor.frontmatter.jobwalllink} />
                    </div>
                  )
                }
              })}
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Sponsoren

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark (filter: {fileAbsolutePath: {regex: "/day/(sponsoren/)/"  }}){
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
            street
            city
            isFoerdermitglied
            homepage
            email
            facebooklink
            twitterlink
            linkedinlink
            xinglink
            instagramlink
            youtubelink
            jobwalllink
            image {
              childImageSharp {
                fixed(width: 500) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }  
    }
    foerdermitgliedImage: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/foerdermitgliedLogo/"}}) {
      edges {
        node {
          id
          childImageSharp {
              fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                originalName
              }
          }
        }
      }
    }
    seoInfo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/day/sponsoren.md/"}}) {
      edges {
        node {
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`
